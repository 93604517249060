import Vue from 'vue';
import VueRouter from 'vue-router';

// Layouts
import FrontendLayout from "@/views/Layouts/Frontend";
import UserDashboardLayout from "@/views/Layouts/UserDashboard";
import AdminLayout from "@/views/Layouts/Admin";

// Children
import { FrontendChildren } from "./frontend";
import { AdminChildren } from "./admin";
import { UserDashboardChildren } from "./user_dashboard";

import XpressDoxSuccess from "@/views/XpressDoxSuccess";

Vue.use(VueRouter)

  const routes = [
  {
    path: "/",
    component: FrontendLayout,
    ...FrontendChildren
  },
  {
    path: "/admin",
    component: AdminLayout,
    ...AdminChildren
  },
  {
    path: "/user",
    component: UserDashboardLayout,
    ...UserDashboardChildren
  },
  {
    path: "/xpressdox/success/:order/:product",
    name: "XpressDox Success",
    component: XpressDoxSuccess
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
})

router.beforeEach(async (to, from, next) => {
  const { authorize } = to.meta
  const loggedIn = localStorage.getItem('token')
  const role = localStorage.getItem('role')

  if (to.fullPath.substr(0,2) === "/#") {
    const path = to.fullPath.substr(2)
    next(path)
    return;
  }

  if (authorize && !loggedIn) return next({name: "Login"})

  if (authorize && !role) return next({name: "Login"})
  
  if (role && authorize && !authorize.includes(role)) return next({name: "Login"})
  return next()
})

export default router
