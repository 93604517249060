<template>
  <v-app>
    <v-app-bar app dark class="header">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="pointer" @click="$router.push('/')">
        <v-img height="40" :contain="true" :src="img"></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-list light nav dense class="bkg-transparent">
        <v-list-item href="/articles">
          <v-list-item-title>ARTICLES</v-list-item-title>
        </v-list-item>
      </v-list>
      <!-- <v-list light nav dense class="bkg-transparent">
        <v-list-item href="/vacancies">
          <v-list-item-title>JOB VACANCIES</v-list-item-title>
        </v-list-item>
      </v-list> -->
      <v-list light nav dense class="bkg-transparent">
        <v-list-item href="/products">
          <v-list-item-title>DIY DOCUMENTS</v-list-item-title>
        </v-list-item>
      </v-list>
      <cart-menu v-if="user"></cart-menu>
      <account-menu :user="user"></account-menu>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-list nav dense>
        <div v-if="!user">
          <v-list-item href="/login">
            <v-list-item-icon>
              <v-icon>mdi-account-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Login</v-list-item-title>
          </v-list-item>
        </div>

        <div v-if="user">
          <v-list-item class="mt-3">
            <v-list-item-content>
              <v-list-item-title
                >Welcome <strong>{{ user.fullName }}</strong></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="me.role == 'admin'" href="/admin/dashboard">
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Admin Dashboard</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="me.role == 'admin' || me.role == 'user'" href="/user/purchases">
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>User Dashboard</v-list-item-title>
          </v-list-item>

          <v-list-group prepend-icon="mdi-account-circle-outline" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Profile</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="(item, index) in profList" :key="index" :href="item.href" link>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item @click="logout" class="mb-3">
            <v-list-item-icon>
              <v-icon>mdi-location-exit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </div>

        <v-divider></v-divider>

        <v-list-item href="/" class="mt-3">
          <v-list-item-icon>
            <v-icon>mdi-home-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>

        <v-list-item href="/articles">
          <v-list-item-icon>
            <v-icon>mdi-file-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Articles</v-list-item-title>
        </v-list-item>

        <v-list-item href="/products">
          <v-list-item-icon>
            <v-icon>mdi-shopping-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>DIY Documents</v-list-item-title>
        </v-list-item>

        <!-- <v-list-item href="/vacancies">
          <v-list-item-icon>
            <v-icon>mdi-briefcase-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Job Vacancies</v-list-item-title>
        </v-list-item> -->

        <v-list-group prepend-icon="mdi-bank-outline" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>What We Do</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="(item, index) in wwdList" :key="index" :href="item.href">
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-item href="/why-us">
          <v-list-item-icon>
            <v-icon>mdi-account-question-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Why Us</v-list-item-title>
        </v-list-item>

        <v-list-item href="/wealth">
          <v-list-item-icon>
            <v-icon>mdi-account-cash-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Wealth</v-list-item-title>
        </v-list-item>

        <v-list-item href="/contact">
          <v-list-item-icon>
            <v-icon>mdi-phone-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Contact Us</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer class="footer mt-5" padless>
      <v-container fluid>
        <v-row class="section__row" v-show="$route.name !== 'Contact' && $route.name !== 'Login'">
          <div class="section__container mt-12">
            <v-col class="section__column">
              <h4 class="section__name txtWhite">Talk to Us</h4>
              <h3 class="section__subtitle txtWhite">
                We’d love to hear what you’re up to. Of course, there’s no charge for our first chat.
              </h3>
              <a class="section__btn btnWhite" href="/contact">Contact Us</a>
            </v-col>
            <v-col class="section__column">
              <img class="section__image" :src="require('@/assets/img/Touche.png')" alt="Touche" />
            </v-col>
          </div>
        </v-row>
        <v-row class="section__container">
          <v-col class="pad0bot">
            <img class="footer__image" height="60" :contain="true" :src="require('@/assets/img/NLA-logo-light.svg')" />
          </v-col>
        </v-row>
        <v-row class="section__container">
          <v-col class="pad0top">
            <v-row>
              <v-col md="2">
                <a class="footer-link" href="/"><strong>HOME</strong></a>
              </v-col>
              <v-col md="2">
                <a class="footer-link" href="/what-we-do"><strong>WHAT WE DO</strong></a>
                <a v-for="(item, index) in wwdList" :key="index" class="footer-link" :href="item.href">{{ item.name }}</a>
              </v-col>
              <v-col md="2">
                <a class="footer-link" href="/products"><strong>DIY DOCUMENTS</strong></a>
              </v-col>
              <v-col md="2">
                <a class="footer-link" href="/why-us"><strong>WHY US?</strong></a>
              </v-col>
              <v-col md="2">
                <a class="footer-link" href="/wealth"><strong>WEALTH</strong></a>
              </v-col>
              <v-col md="2">
                <a class="footer-link" href="/contact"><strong>CONTACT US</strong></a>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-divider></v-divider>

      <v-row>
        <v-col class="text-center">
          <strong class="footer-copy">© NLA Legal {{ new Date().getFullYear() }}</strong>
          <a class="ml-2 footer-link footer-link--inline" href="/policies-disclaimers">Policies &amp; Disclaimers</a>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { gql } from "apollo-boost";
import { User } from "@/models/User";
import AccountMenu from "@/components/Shared/Menus/AccountMenu";
import CartMenu from "@/components/Shared/Menus/CartMenu";

export default {
  components: {
    AccountMenu,
    CartMenu,
  },
  methods: {
    isContact() {
      return this.$route.name === "Contact";
    },
  },
  data() {
    return {
      drawer: false,
      isLoading: false,
      errorMsg: "",
      successMsg: "",

      me: null,
      user: null,
      img: require("@/assets/img/NLA-logo.svg"),

      wwdList: [
        { name: "Building Your Business", href: "/building-your-business" },
        { name: "Litigation", href: "/litigation" },
        { name: "Family Law", href: "/family-law" },
        // {name: "Property Law", href:"/property-law"},
        { name: "Labour Law", href: "/labour-law" },
        { name: "Compliance", href: "/compliance" },
        { name: "Trusts", href: "/trusts" },
        { name: "Estate Planning", href: "/estate-planning" },
        { name: "Notarial Services", href: "/notarial-services" },
      ],

      profList: [
        { name: "Personal Information", href: "/user/profile" },
        { name: "Purchases", href: "/user/purchases" },
      ],
    };
  },
  apollo: {
    me: {
      query: gql`
        query {
          me {
            id
            role
            firstName
            lastName
          }
        }
      `,
      fetchPolicy: "network-only",
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result(response, key) {
        if (response.data) {
          this.user = new User();
          this.user.id = this.me.id;
          this.user.role = this.me.role;
          this.user.firstName = this.me.firstName;
          this.user.lastName = this.me.lastName;
        }
      },
      error(error) {
        this.errorMsg = error;
      },
    },
  },
  created() {
    this.$eventHub.$on("logged-in", this.loggedIn);
  },
  beforeDestroy() {
    this.$eventHub.$off("logged-in");
  },
  methods: {
    loggedIn() {
      const self = this;
      this.$apollo.queries.me.refetch().then(() => {
        self.$eventHub.$emit("addProductAfterLogin");
      });
    },
    logout() {
      localStorage.setItem("token", "");
      localStorage.setItem("role", "");
      this.$router.go(this.$router.currentRoute);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../../assets/css/scss/variables";
.section {
  &__row {
    background-color: $dark-background;
  }
  &__column {
    width: 50%;
    padding: 0;
  }
  &__subtitle {
    font-family: $garamond;
    margin: 25% 0 9px 0;
    font-size: 25px;
    line-height: 130%;
    font-style: italic;
    font-weight: 400;
  }
  &__name {
    margin-top: 30px;
    margin-bottom: 50px;
    font-family: $garamond;
    color: $dark-background;
    font-size: 33px;
    line-height: 120%;
    padding-bottom: 4px;
    border-bottom: 2px solid $primary;
    font-weight: 700;
    display: inline-block !important;
    height: auto;
  }
  &__container {
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
    padding: 0;
    display: flex;
  }

  &__image {
    margin-bottom: -7px;
  }
  &__btn {
    margin-top: 2%;
    display: inline-block;
    padding: 16px 25px;
    border: 1px solid $primary;
    background-color: $btn-background;
    font-family: $lato;
    color: $primary;
    font-size: 20px;
    line-height: 20px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;
    &:hover {
      background-color: $primary;
      color: $btn-hover;
    }
  }
}

.txtWhite {
  color: $white;
}

.btnWhite {
  background-color: rgba(0, 0, 0, 0.51);
}

.pad0top {
  padding-top: 0;
}

.pad0bot {
  padding-bottom: 0;
}

.pointer {
  cursor: pointer;
}

.posr-15 {
  position: absolute;
  right: 15px;
}

.header {
  background: $primary;
  background: linear-gradient(90deg, $white 0%, $primary 100%);
  color: $white;
}

.mdi-menu::before {
  color: $primary;
}

.footer {
  background-color: $darker-background !important;
  padding: 0;

  &-link {
    text-decoration: none;
    color: $primary;
    display: block;
    &--inline {
      display: inline-block;
    }
  }
  &-copy {
    color: $light-text;
  }
  &-line {
    min-height: 25px;
  }
  &__image {
    text-align: left;
  }
}

.container {
  padding: 0;
}

@media (max-width: 1023px) {
  .section {
    &__container {
      flex-direction: column;
      max-width: 100vw;
      padding: 0 30px;
    }
    &__column {
      width: 100%;
    }
    &__subtitle {
      margin: 0;
    }
  }
}
</style>
