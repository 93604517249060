import { loadView } from "./functions";

export const UserDashboardChildren = {
  children: [
    {
      path: "dashboard",
      name: "User Dashboard",
      component: loadView("UserDashboard/Home"),
      ...userMeta()
    },
    {
      path: "purchases",
      name: "User Purchases",
      component: loadView("UserDashboard/Purchases"),
      ...userMeta()
    },
    {
      path: "purchases/order/:order_id",
      name: "User Purchases invoice",
      component: loadView("UserDashboard/Purchases/Details"),
      ...userMeta()
    },
    {
      path: "purchases/survey/:order_id/:product_id",
      name: "Order Survey",
      component: loadView("UserDashboard/Purchases/Survey"),
      ...userMeta()
    },
    {
      path: "purchases/survey/complete",
      name: "Order Survey Success",
      component: loadView("UserDashboard/Purchases/SurveyComplete"),
      ...userMeta()
    },
    {
      path: "notifications",
      name: "User Notifications",
      component: loadView("UserDashboard/Notifications"),
      ...userMeta()
    },
    {
      path: "profile",
      name: "User Profile",
      component: loadView("UserDashboard/Profile"),
      ...userMeta()
    },
    {
      path: "preferences",
      name: "User Preferences",
      component: loadView("UserDashboard/Settings"),
      ...userMeta()
    },
    {
      path: "view",
      name: "View",
      component: loadView("UserDashboard/View"),
      ...userMeta()
    },
  ]
}

function userMeta() {
  return { 
    meta: { authorize: [
      "admin",
      "user"
    ]}
  }
}