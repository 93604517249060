import { loadView } from "./functions";

export const AdminChildren = {
  children: [
    {
      path: "dashboard",
      name: "Dashboard",
      component: loadView("Admin/Home"),
      ...userMeta(),
    },
    {
      path: "orders",
      name: "Orders",
      component: loadView("Admin/Orders"),
      ...userMeta(),
    },
    {
      path: "order/:order_id",
      name: "Order",
      component: loadView("Admin/Commerce/Orders/Details"),
      ...userMeta(),
    },
    {
      path: "products",
      name: "Commerce",
      component: loadView("Admin/Products"),
      ...userMeta(),
    },
    {
      path: "product/:id",
      name: "Product Detail",
      component: loadView("Admin/Commerce/Products/Details"),
      ...userMeta(),
    },
    {
      path: "product/:id/edit",
      name: "Product Edit",
      component: loadView("Admin/Commerce/Products/Edit"),
      ...userMeta(),
    },
    {
      path: "articles",
      name: "Articles View",
      component: loadView("Admin/Articles"),
      ...userMeta(),
    },
    {
      path: "article/:id",
      name: "Article Detail",
      component: loadView("Admin/Articles/Details"),
      ...userMeta(),
    },
    {
      path: "article/:id/edit",
      name: "Article Edit",
      component: loadView("Admin/Articles/Edit"),
      ...userMeta(),
    },
    {
      path: "users",
      name: "Users",
      component: loadView("Admin/Users"),
      ...userMeta(),
    },
    {
      path: "user/:id/edit",
      name: "User Details Edit",
      component: loadView("Admin/Users/Edit"),
      ...userMeta(),
    },
  ],
};

function userMeta() {
  return {
    meta: { authorize: ["admin"] },
  };
}
