<template>
  <v-card>
    <v-card-text>
      <h3 style="margin-bottom: 10px" align="center">Download Links</h3>
      <v-btn
        style="margin-bottom: 10px "
        width="100%"
        v-for="(item, index) in links"
        :key="index"
        @click="download(item.DownloadLink._text)"
      >
        <v-img
          :contain="true"
          :src="getSrc(item.FileType._text)"
          height="30px"
          max-width="30px"
        ></v-img>
        <span class="secondary--text">
          &nbsp&nbsp {{ getFileName(item.FilePath._text) }} - ({{
            item.FileSize._text
          }})
        </span>
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    links: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      docxImg: require("@/assets/img/docx.png"),
      pdfImg: require("@/assets/img/pdf.png"),
    };
  },
  methods: {
    getFileName(filePath) {
      const dirs = filePath.split("\\");
      return dirs[dirs.length - 1];
    },
    getSrc(fileType) {
      if (fileType == "MS Word 2007") return this.docxImg;
      return this.pdfImg;
    },
    download(url) {
      window.location.href = url;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  text-transform: none !important;
}
</style>
