<template>
  <div>
    <v-btn 
      class="mar15left"
      color="light"
      dark
      outlined
      v-if="!user"
      @click="$router.push('/login')">Login</v-btn>
    
    <v-menu
      offset-y
      bottom
      origin="center center"
      transition="scale-transition"
      :close-on-content-click="false"
      min-width="240"
      v-if="user">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="light"
          dark
          outlined
          v-bind="attrs"
          v-on="on"
          class="ml-5">
          <v-icon class="mr-2">mdi-account-circle-outline</v-icon> &nbsp;{{user.fullName}}
        </v-btn>
      </template>

      <v-list>
        <v-list-item href="/user/purchases">
        <v-list-item-icon>
          <v-icon>mdi-view-dashboard-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Dashboard</v-list-item-title>
      </v-list-item>
      
      <v-list-group
        prepend-icon="mdi-account-circle-outline"
        no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item
          v-for="(item, index) in profList" 
          :key="index"
          :href="item.href"
          link>
          <v-list-item-content>
            <v-list-item-title>{{item.name}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-item @click="logout" class="mb-3">
        <v-list-item-icon>
          <v-icon>mdi-location-exit</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Logout</v-list-item-title>
      </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  export default {
    props: {
      user: {
        type: Object,
        default: {}
      },
    },
    data() {
      return {
        profList: [
          {name: "Personal Information", href: "/user/profile"},
          {name: "Purchases", href: "/user/purchases"},
        ]
      }
    },
    methods: {
      logout() {
        localStorage.setItem('token', '')
        localStorage.setItem('role', '')
        this.$router.go(this.$router.currentRoute)
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>