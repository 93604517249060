<template>
  <v-dialog :width="width" v-model="show">
    <v-card>
      <v-card-title>
        <slot name="header"></slot>
      </v-card-title>
      <v-card-text>
        <slot name="body"></slot>
      </v-card-text>
      <v-card-actions>
        <slot name="footer"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      show: {
        type: Boolean,
        default: false
      },
      width: Number,
      default: 500
    },
  }
</script>

<style lang="scss" scoped>

</style>