<template>
  <v-app>
    <v-app-bar app  class="header">
      <v-toolbar-title class="nav__title">Dashboard</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu 
        offset-y
        bottom
        transition="scale-transition"
        :close-on-content-click="false"
        min-width="240">
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            color="light"
            dark
            outlined
            v-bind="attrs"
            v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item 
            class="mb-3"
            @click="$router.push('/admin/orders')"
            v-if="me && me.role=='admin'">
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Admin Dashboard</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout" class="mb-3">
            <v-list-item-icon>
              <v-icon>mdi-location-exit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer app permanent dark class="nav__drawer">
      <v-list-item @click="$router.push('/')">
        <v-list-item-content class="nav__drawer__logo">
          <v-img :contain="true" height="40" src="http://nlateam.co.za/images/NLA-Logo-Topnav-0.1.svg"></v-img>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item v-for="item in items" :key="item.title" link :to="item.href">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <v-col>
          <transition name="fade">
            <router-view />
          </transition>
        </v-col>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
import { gql } from 'apollo-boost'
export default {
  data () {
    return {
      items: [
        // { title: 'Dashboard', icon: 'mdi-view-dashboard', href: "/user/dashboard" },
        { title: 'Purchases', icon: 'mdi-cart-outline', href: "/user/purchases" },
        // { title: 'Notifications', icon: 'mdi-bell-outline', href: "/user/notifications" },
        { title: 'Profile', icon: 'mdi-account-circle-outline', href: "/user/profile" },
        // { title: 'Preferences', icon: 'mdi-cog', href: "/user/preferences" },
      ],
      right: null,
    }
  },
  apollo: {
    me: {
      query: gql`query {
        me {
          id
          role
        }
      }`
    }
  },
  methods: {
    logout() {
      localStorage.setItem('token', '')
      localStorage.setItem('role', '')
      this.$router.push("/")
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/scss/_variables.scss";
.section {
    &__row {
      background-color: $dark-background;
    }
    &__column {
      width: 50%;
      padding: 0;
    }
    &__subtitle {
      font-family: $garamond;
      margin: 25% 0 9px 0;
      font-size: 25px;
      line-height: 130%;
      font-style: italic;
      font-weight: 400;
    }
    &__name {
      margin-top: 30px;
      margin-bottom: 50px;
      font-family: $garamond;
      color: $dark-background;
      font-size: 33px;
      line-height: 120%;
      padding-bottom: 4px;
      border-bottom: 2px solid $primary;
      font-weight: 700;
      display: inline-block !important;
      height: auto;
    }
    &__container {
      margin-left: auto;
      margin-right: auto;
      max-width: 940px;
      padding: 0;
      display: flex;
    }

    &__image {
      margin-bottom: -7px;
    }
    &__btn {
      margin-top: 2%;
      display: inline-block;
      padding: 16px 25px;
      border: 1px solid $primary;
      background-color: $btn-background;
      font-family: $lato;
      color: $primary;
      font-size: 20px;
      line-height: 20px;
      text-decoration: none;
      cursor: pointer;
      border-radius: 0;
      &:hover {
        background-color: $primary;
        color: $btn-hover;
      }
    }
  }

  .txtWhite {
  color: $white;
}

.btnWhite {
  background-color: rgba(0, 0, 0, 0.51);
}

.pad0top {
  padding-top: 0;
}

.pad0bot {
  padding-bottom: 0;
}

.pointer {
  cursor: pointer;
}

.posr-15 {
  position: absolute;
  right: 15px;
}

.header {
    background: $primary;
    background: linear-gradient(90deg, $white 0%, $primary 100%);
    color: $white;
}

.mdi-menu::before {
    color: $primary;
}

.nav__title {
  color: $dark-background;
}
</style>