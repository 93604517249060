<template>
  <v-container>
    <v-row>
      <v-col md="6">
        <h2 align="center">Success</h2>
        <h4 style="margin-bottom: 10px" align="center">
          Please find assembled files below:
        </h4>
        <download-links
          v-if="downloadLinksReady"
          :links="download_links"
        ></download-links>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { gql } from "apollo-boost";
import DownloadLinks from "@/components/Shared/DownloadLinks";
export default {
  data() {
    return {
      skipQuery: true,
      product: {},
      downloadLinksReady: false,
      download_links: [],
    };
  },
  components: {
    DownloadLinks,
  },
  apollo: {
    order: {
      query: gql`
        query order($id: ID!) {
          order(id: $id) {
            id
            products
            payment
            status
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        return {
          id: self.$route.params.order,
        };
      },
      result(response) {
        const self = this;
        self.product = self.order.products.filter(
          (item) => item.id == self.$route.params.product
        )[0];
        self.$apollo.queries.xpressDoxReturns.skip = false;
        self.$apollo.queries.xpressDoxReturns.refetch();
      },
    },
    xpressDoxReturns: {
      query: gql`
        query XpressDoxReturns($limit: Int!, $skip: Int!, $query: JSON!) {
          xpressDoxReturns(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              order
              product
              data
              createdAt
              updatedAt
            }
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return this.skipQuery;
      },
      variables() {
        const self = this;
        return {
          limit: 1,
          skip: 0,
          query: {
            order: self.$route.params.order,
            product: self.$route.params.product,
          },
        };
      },
      result() {
        const self = this;
        if (self.order && self.product) {
          self.updateProduct();
        }
      },
    },
    me: {
      query: gql`
        query {
          me {
            id
          }
        }
      `,
    },
  },
  methods: {
    updateProduct() {
      this.product.download_links = this.xpressDoxReturns.records[0].data.ArrayOfAssemblyUsage.AssemblyUsage.AssembledFiles.AssembledFile;
      this.download_links = [];
      if (Array.isArray(this.product.download_links)) {
        this.download_links = this.product.download_links;
      } else {
        this.download_links.push(this.product.download_links);
      }
      this.product.status = "completed";
      this.downloadLinksReady = true;
      this.updateOrder();
    },

    async updateOrder() {
      const self = this;
      var status = "new";
      if (
        self.order.products.filter((item) => item.status == "completed")
          .length == self.order.products.length
      ) {
        status = "completed";
      }
      await self.$apollo.mutate({
        mutation: gql`
          mutation updateOrder($order: OrderUpdateInput!) {
            updateOrder(order: $order) {
              id
              updated
            }
          }
        `,
        variables: {
          order: {
            id: self.$route.params.order,
            products: self.order.products,
            status: status,
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
