<template>
  <div>
    <v-menu offset-y bottom origin="center center" transition="scale-transition" min-width="320">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="light" dark outlined v-bind="attrs" v-on="on"> <v-icon class="mr-2">mdi-cart-outline</v-icon> ({{ totalItems }}) </v-btn>
      </template>

      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-data-table :headers="cartHeaders" :items="cartRows" :items-per-page="5" class="elevation-1" hide-default-footer>
              <template v-slot:item.bin="{ bin }">
                <v-icon>mdi-delete</v-icon>
              </template>
              <template v-slot:item.amount="{ item }">
                <h5>
                  {{
                    item.amount
                      | currency("R", 2, {
                        thousandsSeparator: ",",
                        decimalSeparator: ".",
                      })
                  }}
                </h5>
              </template>
            </v-data-table>
            <v-row> </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-data-table :headers="summaryHeaders" :items="summaryRows" hide-default-header hide-default-footer class="elevation-0 mt-3">
              <template v-slot:item.amount="{ item }">
                <h5>
                  {{
                    item.amount
                      | currency("R", 2, {
                        thousandsSeparator: ",",
                        decimalSeparator: ".",
                      })
                  }}
                </h5>
              </template>
            </v-data-table>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-btn small @click="$router.push('/commerce/cart')" class="btn__primary-colour">View Cart</v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
export default {
  data() {
    return {
      skipQuery: true,

      totalItems: 0,

      cart: {},

      products: [],
      cartRows: [],
      summaryRows: [],

      cartHeaders: [
        { text: "Item", value: "item", sortable: false, align: "start" },
        { text: "Qty", value: "qty", sortable: false },
        { text: "Total", value: "amount", sortable: false, align: "right" },
        // { text: "Delete", value: "bin", sortable: false, align: "right" },
      ],

      summaryHeaders: [
        { text: "summary", align: "start", sortable: false, value: "summary" },
        { text: "amount", align: "right", sortable: false, value: "amount" },
      ],
    };
  },
  apollo: {
    orders: {
      query: gql`
        query Orders($limit: Int!, $skip: Int!, $query: JSON!) {
          orders(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              totalAmount
              products
              createdAt
            }
          }
        }
      `,
      skip() {
        return this.skipQuery;
      },
      variables() {
        const self = this;
        return {
          limit: 1,
          skip: 0,
          query: {
            createdBy: self.me.id,
            payment: "pending",
          },
        };
      },
      fetchPolicy: "network-only",
      result(response) {
        if (!response?.data?.orders?.records?.length) {
          this.initState();
          return;
        }
        this.cart = this.orders.records[0];
        this.products = this.cart.products;
        this.totalItems = this.products.length;

        const grouped = _.groupBy(this.products, "id");
        const entries = Object.entries(grouped);

        this.cartRows = [];
        for (const [product_id, value] of entries) {
          this.cartRows.push({
            item: value[0].title,
            qty: value.length,
            amount: value[0].price,
          });
        }

        this.summaryRows = [];
        const total = _.map(this.cartRows, (item) => {
          return item.qty * item.amount;
        }).reduce((a, b) => a + b);
        this.summaryRows.push({
          summary: "Total Excl Vat",
          amount: total / 1.15,
        });
        this.summaryRows.push({
          summary: "Vat",
          amount: (total / 1.15) * 0.15,
        });
        this.summaryRows.push({
          summary: "Total Incl Vat",
          amount: total,
        });
      },
    },
    me: {
      query: gql`
        query {
          me {
            id
          }
        }
      `,
      fetchPolicy: "network-only",
      result() {
        this.$apollo.queries.orders.skip = false;
        this.$apollo.queries.orders.refetch();
      },
    },
  },
  created() {
    this.$eventHub.$on("addProductToCart", this.addProduct);
    this.$eventHub.$on("addProductToCartAfterLogin", this.addProductAfterLogin);
    this.$eventHub.$on("updateCartMenu", (product) => {
      const self = this;
      this.$apollo.queries.orders.skip = false;
      this.$apollo.queries.me.refetch().then(() => {
        self.$apollo.queries.orders.refetch().then(() => {
          if (product) {
            this.addProductAfterLogin(product);
          }
        });
      });
    });
  },
  beforeDestroy() {
    this.$eventHub.$off("addProductToCart");
    this.$eventHub.$off("addProductToCartAfterLogin");
    this.$eventHub.$off("updateCartMenu");
  },
  methods: {
    initState() {
      this.totalItems = 0;
      this.cart = {};
      this.products = [];
      this.cartRows = [];
      this.summaryRows = [];
    },

    addProduct(product) {
      const isOld = this.products.length > 0;
      this.products.push(product);
      this.$swal({
        title: "Success",
        text: "DIY Document Added to Cart.",
        icon: "success",
        confirmButtonColor: "#c79477",
      });
      if (isOld) {
        this.updateOrder();
      } else {
        this.createOrder();
      }
    },

    addProductAfterLogin(product) {
      const self = this;
      this.$apollo.queries.me.refetch().then(() => {
        self.$apollo.queries.orders.refetch().then(() => {
          const isOld = self.products.length > 0;
          self.products.push(product);
          self.$swal({
            title: "Success",
            text: "DIY Document Added to Cart.",
            icon: "success",
            confirmButtonColor: "#c79477",
          });
          if (isOld) {
            self.updateOrder();
          } else {
            self.createOrder();
          }
        });
      });
    },

    async updateOrder() {
      const self = this;

      await self.$apollo.mutate({
        mutation: gql`
          mutation updateOrder($order: OrderUpdateInput!) {
            updateOrder(order: $order) {
              id
              updated
            }
          }
        `,
        variables: {
          order: {
            id: self.cart.id,
            products: self.products,
          },
        },
      });
    },

    async createOrder() {
      const self = this;

      var response = await self.$apollo.mutate({
        mutation: gql`
          mutation createOrder($order: OrderCreateInput!) {
            createOrder(order: $order) {
              id
            }
          }
        `,
        variables: {
          order: {
            totalAmount: self.products[0].price,
            products: self.products,
            createdBy: self.me.id,
            deliveryDate: Date.now(),
            deliveryAddress: "",
            payment: "pending",
            status: "new",
          },
        },
      });

      if (response?.data?.createOrder?.id) {
        this.$apollo.queries.orders.refetch();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
