export class User {
  constructor() {
    this.id = ""
    this.email = ""
    this.telNumber = ""
    this.password = ""
    this.confirm = ""
    this.firstName = ""
    this.lastName = ""
    this.role = ""
    this.terms = ""

    this.location = {}
    this.location.addy1 = ""
    this.location.addy2 = ""
    this.location.suburb = ""
    this.location.city = ""
    this.location.country = ""
    this.location.postcode = ""
  }

  get fullAddress() {
    const values = Object.values(this.location)
    return values.filter(item => item).join(', ').trim();
  }

  get fullName() {
    var toArray = [this.firstName, this.lastName]
    return toArray.filter(item => item).join(' ').trim()
  }
}