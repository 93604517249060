<template>
  <v-app>
    <v-app-bar app dark class="header">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Dashboard</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y bottom transition="scale-transition" :close-on-content-click="false" min-width="240">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="light" dark outlined v-bind="attrs" v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="$router.push('/user/purchases')">
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>User Dashboard</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout" class="mb-3">
            <v-list-item-icon>
              <v-icon>mdi-location-exit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" dark app class="nav__drawer">
      <v-list-item @click="$router.push('/')">
        <v-list-item-content class="nav__drawer__logo">
          <v-img :contain="true" height="40" src="http://nlateam.co.za/images/NLA-Logo-Topnav-0.1.svg"></v-img>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item v-for="item in items" :key="item.title" link :to="item.href">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <v-col>
          <transition name="fade">
            <router-view />
          </transition>
        </v-col>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { gql } from "apollo-boost";
import { User } from "@/models/User";
// import { mapActions } from 'vuex'

export default {
  data() {
    return {
      drawer: null,

      me: null,
      user: null,

      items: [
        // { title: 'Dashboard', icon: 'mdi-view-dashboard', href: "/admin/dashboard" },
        // { title: 'Blogs', icon: 'mdi-book-open-blank-variant', href: "/admin/blogs" },
        // { title: 'Content', icon: 'mdi-table-of-contents', href: "/admin/content" },
        { title: "Articles", icon: "mdi-file-outline", href: "/admin/articles" },
        { title: "Orders", icon: "mdi-book-check-outline", href: "/admin/orders" },
        { title: "DIY Documents", icon: "mdi-cart-outline", href: "/admin/products" },
        { title: "Users", icon: "mdi-account-circle-outline", href: "/admin/users" },
        // { title: 'Taxonomy', icon: 'mdi-sitemap', href: "/admin/taxonomy" },
        // { title: 'Notifications', icon: 'mdi-bell-outline', href: "/admin/notifications" },
        // { title: 'Settings', icon: 'mdi-cog', href: "/admin/settings" },
      ],
      right: null,
    };
  },
  apollo: {
    me: {
      query: gql`
        query {
          me {
            id
            role
            firstName
            lastName
          }
        }
      `,
      fetchPolicy: "network-only",
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result(response, key) {
        if (response.data) {
          this.user = new User();
          this.user.id = this.me.id;
          this.user.role = this.me.role;
          this.user.firstName = this.me.firstName;
          this.user.lastName = this.me.lastName;
        }
      },
      error(error) {
        this.errorMsg = error;
      },
    },
  },
  methods: {
    logout() {
      localStorage.setItem("token", "");
      localStorage.setItem("role", "");
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped></style>
