import { loadView } from "./functions";

export const FrontendChildren = {
  children: [
    //TODO Remove this as it is for demo
    {
      path: "/payfast/:order_id",
      name: "Payfast",
      component: loadView("Frontend/Commerce/Payfast"),
    },
    {
      path: "/",
      name: "Landing",
      component: loadView("Frontend/Home"),
    },
    {
      path: "/login",
      name: "Login",
      component: loadView("Frontend/User/Login"),
    },
    {
      path: "/register-confirm",
      name: "Confirm Email",
      component: loadView("Frontend/User/RegisterConfirm"),
    },
    {
      path: "/password-reset/:id",
      name: "Password Reset",
      component: loadView("Frontend/User/PasswordReset"),
    },
    {
      path: "/contact",
      name: "Contact",
      component: loadView("Frontend/Contact"),
    },
    {
      path: "/products",
      name: "Products",
      component: loadView("Frontend/Products/List"),
    },
    {
      path: "/products/:id",
      name: "Products Details",
      component: loadView("Frontend/Products/Details"),
    },
    {
      path: "/articles",
      name: "Articles",
      component: loadView("Frontend/Articles/List"),
    },
    {
      path: "/articles/:id",
      name: "Articles Details",
      component: loadView("Frontend/Articles/Details"),
    },
    {
      path: "/vacancies",
      name: "Vacancies",
      component: loadView("Frontend/Vacancies/List"),
    },
    {
      path: "/commerce/cart",
      name: "Cart",
      component: loadView("Frontend/Commerce/Cart"),
    },
    {
      path: "/commerce/checkout",
      name: "Checkout",
      component: loadView("Frontend/Commerce/Checkout"),
    },
    {
      path: "/commerce/cancel",
      name: "Checkout Cancel",
      component: loadView("Frontend/Commerce/Cancel"),
    },
    {
      path: "/commerce/success/:order_id",
      name: "Checkout Success",
      component: loadView("Frontend/Commerce/Success"),
    },
    {
      path: "/building-your-business",
      name: "Building Your Business",
      component: loadView("Frontend/BuildingBusiness"),
    },
    {
      path: "/litigation",
      name: "Litigation",
      component: loadView("Frontend/Litigation"),
    },
    {
      path: "/family-law",
      name: "Family Law",
      component: loadView("Frontend/FamilyLaw"),
    },
    {
      path: "/property-law",
      name: "Propery Law",
      component: loadView("Frontend/PropertyLaw"),
    },
    {
      path: "/labour-law",
      name: "Labour Law",
      component: loadView("Frontend/LabourLaw"),
    },
    {
      path: "/compliance",
      name: "Compliance",
      component: loadView("Frontend/Compliance"),
    },
    {
      path: "/trusts",
      name: "Trusts",
      component: loadView("Frontend/Trusts"),
    },
    {
      path: "/estate-planning",
      name: "Estate Planning",
      component: loadView("Frontend/EstatePlanning"),
    },
    {
      path: "/notarial-services",
      name: "Notarial Services",
      component: loadView("Frontend/NotarialServices"),
    },
    {
      path: "/why-us",
      name: "Why Us",
      component: loadView("Frontend/WhyUs"),
    },
    {
      path: "/wealth",
      name: "Wealth",
      component: loadView("Frontend/Wealth"),
    },
    {
      path: "/what-we-do",
      name: "What We Do",
      component: loadView("Frontend/WhatWeDo"),
    },
    {
      path: "/policies-disclaimers",
      name: "Policies and Disclaimers",
      component: loadView("Frontend/PoliciesDisclaimers"),
    },
  ],
};
