var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"offset-y":"","bottom":"","origin":"center center","transition":"scale-transition","min-width":"320"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"light","dark":"","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-cart-outline")]),_vm._v(" ("+_vm._s(_vm.totalItems)+") ")],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.cartHeaders,"items":_vm.cartRows,"items-per-page":5,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.bin",fn:function(ref){
var bin = ref.bin;
return [_c('v-icon',[_vm._v("mdi-delete")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('h5',[_vm._v(" "+_vm._s(_vm._f("currency")(item.amount,"R", 2, { thousandsSeparator: ",", decimalSeparator: ".", }))+" ")])]}}])}),_c('v-row')],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-data-table',{staticClass:"elevation-0 mt-3",attrs:{"headers":_vm.summaryHeaders,"items":_vm.summaryRows,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('h5',[_vm._v(" "+_vm._s(_vm._f("currency")(item.amount,"R", 2, { thousandsSeparator: ",", decimalSeparator: ".", }))+" ")])]}}])})],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-btn',{staticClass:"btn__primary-colour",attrs:{"small":""},on:{"click":function($event){return _vm.$router.push('/commerce/cart')}}},[_vm._v("View Cart")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }